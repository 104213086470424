import { Modal, ModalBody } from 'reactstrap';
import BaseCom from "./BaseCom";
import L from './Lang';
import Globals from '../shared/Globals';
import { Spinner } from 'reactstrap';

export default class LoadingModal extends BaseCom {

    render() {
        return <><Modal isOpen={this.props.show} toggle={() => { if (this.props.onToggle) this.props.onToggle() }}>
            <ModalBody>
                <div className="p-5">
                    <div className="text-center">
                        <h1><L>{Globals.appName}</L></h1>
                        <hr />
                    </div>
                    <div className="mt-2 text-center">
                        <p className="lead">{this.props.message ?? 'Application is loading...'}</p>
                        <Spinner />
                    </div>
                </div>
            </ModalBody>
        </Modal></>;
    }
}