import BaseCom from './BaseCom';

export default class TOSBuyers extends BaseCom {
    render() {
        if (this.props.lang === 'es') {

            return <div className="container">

                <h2>T&eacute;rminos y Condiciones para Compradores</h2>

                Estos t&eacute;rminos y condiciones ("Acuerdo") rigen tu participaci&oacute;n como comprador en la plataforma Bidea Online ("Plataforma"). Al utilizar la Plataforma, aceptas cumplir con estos t&eacute;rminos y condiciones. Por favor, lee este Acuerdo detenidamente antes de participar en cualquier actividad de compra en la Plataforma.
                <br /><br />
                Registro y Cuenta:
                <br /><br />
                a. Para participar como comprador, debes crear una cuenta en la Plataforma proporcionando informaci&oacute;n precisa y completa.<br />
                b. Eres el &uacute;nico responsable de mantener la confidencialidad de tus credenciales de cuenta y de cualquier actividad que ocurra bajo tu cuenta.<br />
                c. Debes ser mayor de edad para participar como comprador.
                <br /><br />
                Bidea y Compra:
                <br /><br />
                a. Todas las ofertas realizadas en la Plataforma se consideran vinculantes y no se pueden retractar ni cancelar.<br />
                b. El postor con la Oferta m&aacute;s alta al finalizar una subasta se considera el ganador.<br />
                c. Al ganar una subasta, aceptas comprar el art&iacute;culo al precio final de la Oferta.<br />
                d. El pago por el art&iacute;culo debe realizarse dentro del plazo acordado y utilizando los m&eacute;todos de pago especificados.
                <br /><br />
                Descripciones y Estado del Art&iacute;culo:
                <br /><br />
                a. Las descripciones de los art&iacute;culos proporcionadas en la Plataforma se basan en la informaci&oacute;n proporcionada por los vendedores. Bidea Online no garantiza la precisi&oacute;n o integridad de estas descripciones.<br />
                b. Es tu responsabilidad revisar las descripciones de los art&iacute;culos, las fotos y cualquier informaci&oacute;n adicional proporcionada por el vendedor para evaluar el estado y la idoneidad del art&iacute;culo.<br />
                c. Bidea Online alienta a los compradores a inspeccionar los art&iacute;culos en persona siempre que sea posible antes de realizar ofertas.
                <br /><br />
                Prima del Comprador y Tarifas:
                <br /><br />
                a. Se agregar&aacute; una prima del comprador al precio final de la Oferta, como se especifica en el listado de la subasta.<br />
                b. Pueden aplicarse tarifas adicionales, como env&iacute;o, manejo o impuestos, que se te comunicar&aacute;n antes de completar la compra.
                <br /><br />
                Pago y Entrega:
                <br /><br />
                a. Aceptas realizar el pago por el art&iacute;culo comprado dentro del plazo especificado y utilizando los m&eacute;todos de pago acordados.<br />
                b. Bidea Online no es responsable de la entrega, el env&iacute;o o el transporte de los art&iacute;culos comprados. Es responsabilidad del comprador coordinar con el vendedor la entrega o la recogida.
                <br /><br />
                Resoluci&oacute;n de Disputas:
                <br /><br />
                a. En caso de cualquier disputa o desacuerdo entre compradores y vendedores, Bidea Online alienta a ambas partes a entablar una comunicaci&oacute;n abierta para resolver el problema.<br />
                b. Bidea Online no es responsable de ninguna disputa que surja de las transacciones entre compradores y vendedores en la Plataforma.
                <br /><br />
                Limitaci&oacute;n de Responsabilidad:
                <br /><br />
                a. Bidea Online se esfuerza por proporcionar una plataforma confiable y segura, pero no garantiza la precisi&oacute;n, integridad o disponibilidad de la Plataforma ni de ninguna informaci&oacute;n proporcionada en ella.<br />
                b. Bidea Online no ser&aacute; responsable de ning&uacute;n da&ntilde;o directo, indirecto, incidental, especial, consecuente o ejemplar quesurja del uso de la Plataforma.
                <br /><br />
                Ley Aplicable:
                <br /><br />
                a. Este Acuerdo se regir&aacute; e interpretar&aacute; de acuerdo con las leyes federales. Cualquier disputa derivada de este Acuerdo estar&aacute; sujeta a la jurisdicci&oacute;n exclusiva de los tribunales federales.
                <br /><br />
                Al utilizar la Plataforma Bidea Online como comprador, reconoces y aceptas estos t&eacute;rminos y condiciones. Es tu responsabilidad revisar este Acuerdo peri&oacute;dicamente en busca de actualizaciones o modificaciones. Si no est&aacute;s de acuerdo con alguna parte de este Acuerdo, debes abstenerse de utilizar la Plataforma.
                <br /><br />
                Si tienes alguna pregunta o inquietud sobre estos t&eacute;rminos y condiciones, no dudes en ponerte en contacto con nuestro equipo de atenci&oacute;n al cliente en: <i>support@bideaonline.com</i>
                <br /><br />
                Fecha de entrada en vigencia: Julio 6, 2023
            </div>
        }
        
        return <div className="container">
            <h2>Terms and Conditions for Buyers</h2>

            These terms and conditions ("Agreement") govern your participation as a buyer on the Bidea Online platform ("Platform"). By using the Platform, you agree to comply with these terms and conditions. Please read this Agreement carefully before engaging in any buying activities on the Platform.
            <br /><br />
            Registration and Account:
            <br /><br />
            a. To participate as a buyer, you must create an account on the Platform by providing accurate and complete information.<br />
            b. You are solely responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account.<br />
            c. You must be of legal age to participate as a buyer.
            <br /><br />
            Bidding and Purchasing:
            <br /><br />
            a. All bids placed on the Platform are considered binding and cannot be retracted or canceled.<br />
            b. The highest bidder at the conclusion of an auction is considered the winner.<br />
            c. By winning an auction, you agree to purchase the item at the final bid price.<br />
            d. Payment for the item must be made within the agreed-upon timeframe and using the specified payment methods.
            <br /><br />
            Item Descriptions and Condition:
            <br /><br />
            a. Item descriptions provided on the Platform are based on the information provided by the sellers. Bidea Online does not guarantee the accuracy or completeness of these descriptions.<br />
            b. It is your responsibility to review item descriptions, photos, and any additional information provided by the seller to assess the condition and suitability of the item.<br />
            c. Bidea Online encourages buyers to inspect items in person whenever possible before placing bids.
            <br /><br />
            Buyer's Premium and Fees:
            <br /><br />
            a. A buyer's premium, as specified in the auction listing, will be added to the final bid price.<br />
            b. Additional fees, such as shipping, handling, or taxes, may apply and will be communicated to you before completing the purchase.
            <br /><br />
            Payment and Delivery:
            <br /><br />
            a. You agree to make payment for the purchased item within the specified timeframe and using the agreed-upon payment methods.<br />
            b. Bidea Online is not responsible for the delivery, shipping, or transportation of purchased items. It is the buyer's responsibility to coordinate with the seller for delivery or pickup.
            <br /><br />
            Dispute Resolution:
            <br /><br />
            a. In the event of any dispute or disagreement between buyers and sellers, Bidea Online encourages both parties to engage in open communication to resolve the issue.<br />
            b. Bidea Online is not responsible for any disputes arising from transactions between buyers and sellers on the Platform.
            <br /><br />
            Limitation of Liability:
            <br /><br />
            a. Bidea Online strives to provide a reliable and secure platform but does not guarantee the accuracy, completeness, or availability of the Platform or any information provided on it.<br />
            b. Bidea Online shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from your use of the Platform.
            <br /><br />
            Governing Law:
            <br /><br />
            a. This Agreement shall be governed by and construed in accordance with the federal laws. Any disputes arising from this Agreement shall be subject to the exclusive jurisdiction of the federal courts.
            <br /><br />
            By using the Bidea Online Platform as a buyer, you acknowledge and agree to these terms and conditions. It is your responsibility to review this Agreement periodically for any updates or modifications. If you do not agree with any part of this Agreement, you should refrain from using the Platform.
            <br /><br />
            If you have any questions or concerns about these terms and conditions, please contact our customer support team at: <i>support@bideaonline.com</i>
            <br /><br />
            Effective Date: July 6, 2023
        </div>
    }
}