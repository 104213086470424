import BaseCom from './BaseCom';

export default class TOSSellers extends BaseCom {
    render() {
        if (this.props.lang === 'es') {
            return <div className="container">
                <h2>T&eacute;rminos y Condiciones para Vendedores</h2>
                <br /><br />
                Estos t&eacute;rminos y condiciones ("Acuerdo") rigen tu participaci&oacute;n como vendedor en la plataforma Bidea Online ("Plataforma"). Al utilizar la Plataforma, aceptas cumplir con estos t&eacute;rminos y condiciones. Por favor, lee este Acuerdo detenidamente antes de participar en cualquier actividad de venta en la Plataforma.
                <br /><br />
                Registro y Cuenta:
                <br /><br />
                a. Para participar como vendedor, debes crear una cuenta en la Plataforma proporcionando informaci&oacute;n precisa y completa.<br />
                b. Eres el &uacute;nico responsable de mantener la confidencialidad de tus credenciales de cuenta y de cualquier actividad que ocurra bajo tu cuenta.<br />
                c. Debes ser mayor de edad y tener la autoridad para vender los art&iacute;culos que listas en la Plataforma.
                <br /><br />
                Listado y Venta:
                <br /><br />
                a. Eres responsable de proporcionar informaci&oacute;n precisa y completa al crear listados de art&iacute;culos en la Plataforma.<br />
                b. Debes describir con precisi&oacute;n los art&iacute;culos, incluyendo su estado, caracter&iacute;sticas y cualquier detalle relevante.<br />
                c. Debes tener el derecho legal para vender los art&iacute;culos que listas en la Plataforma.
                <br /><br />
                Proceso de Subasta:
                <br /><br />
                a. Al listar un art&iacute;culo para subasta, aceptas vender el art&iacute;culo al postor con la puja m&aacute;s alta al finalizar la subasta.<br />
                b. Debes honrar la venta y completar la transacci&oacute;n con el postor ganador.<br />
                c. Puedes establecer un precio de reserva, que es el precio m&iacute;nimo al cual est&aacute;s dispuesto a vender el art&iacute;culo. Si el precio de reserva no se alcanza, no est&aacute;s obligado a vender el art&iacute;culo.
                <br /><br />
                Tarifas y Comisiones:
                <br /><br />
                a. Bidea Online puede cobrar tarifas o comisiones por utilizar la Plataforma. Estas tarifas se te comunicar&aacute;n antes de listar un art&iacute;culo.<br />
                b. Eres responsable de cualquier impuesto o cargo aplicable asociado a la venta de tus art&iacute;culos.
                <br /><br />
                Comunicaci&oacute;n con los Compradores:
                <br /><br />
                a. Se espera que respondas de manera r&aacute;pida y profesional a las consultas y mensajes de los posibles compradores.<br />
                b. La comunicaci&oacute;n abierta y clara es fundamental para una transacci&oacute;n exitosa.
                <br /><br />
                Pago y Entrega:
                <br /><br />
                a. Eres responsable de proporcionar instrucciones de pago y entrega precisas al comprador.<br />
                b. Es tu responsabilidad entregar el art&iacute;culo al comprador o coordinar su recogida seg&uacute;n lo acordado con el comprador.
                <br /><br />
                Resoluci&oacute;n de Disputas:
                <br /><br />
                a. En caso de cualquier disputa o desacuerdo entre vendedores y compradores, Bidea Online alienta a ambas partes a entablar una comunicaci&oacute;n abierta para resolver el problema.<br />
                b. Bidea Online no es responsable de ninguna disputa que surja de las transacciones entre vendedores y compradores en la Plataforma.
                <br /><br />
                Limitaci&oacute;n de Responsabilidad:
                <br /><br />
                a. Bidea Online se esfuerza por proporcionar una plataforma confiable y segura, pero no garantiza la precisi&oacute;n, integridad o disponibilidad de la Plataforma ni de ninguna informaci&oacute;n proporcionada en ella.<br />
                b. Bidea Online no ser&aacute; responsable de ning&uacute;n da&ntilde;o directo, indirecto, incidental, especial, consecuente o ejemplar que surja del uso de la Plataforma.
                <br /><br />
                Ley Aplicable:
                <br /><br />
                a. Este Acuerdo se regir&aacute; e interpretar&aacute; de acuerdo con las leyes federales. Cualquier disputa derivada de este Acuerdo estar&aacute; sujeta a la jurisdicci&oacute;n exclusiva de los tribunales federales.
                <br /><br />
                Al utilizar la Plataforma Bidea Online como vendedor, reconoces y aceptas estos t&eacute;rminos y condiciones. Es tu responsabilidad revisar este Acuerdo peri&oacute;dicamente en busca de actualizaciones o modificaciones. Si no est&aacute;s de acuerdo con alguna parte de este Acuerdo, debes abstenerse de utilizar la Plataforma.
                <br /><br />
                Si tienes alguna pregunta o inquietud sobre estos t&eacute;rminos y condiciones, no dudes en ponerte en contacto con nuestro equipo de atenci&oacute;n al cliente en: <i>support@bideaonline.com</i>
                <br /><br />
                Fecha de entrada en vigencia: July 6, 2023
            </div>
        }

        return <div className="container">
            <h2>Terms and Conditions for Sellers</h2>

            These terms and conditions ("Agreement") govern your participation as a seller on the Bidea Online platform ("Platform"). By using the Platform, you agree to comply with these terms and conditions. Please read this Agreement carefully before engaging in any selling activities on the Platform.
            <br /><br />
            Registration and Account:
            <br /><br />
            a. To participate as a seller, you must create an account on the Platform by providing accurate and complete information.<br />
            b. You are solely responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account.<br />
            c. You must be of legal age and have the authority to sell the items you list on the Platform.
            <br /><br />
            Listing and Selling:
            <br /><br />
            a. You are responsible for providing accurate and complete information when creating item listings on the Platform.<br />
            b. You must accurately describe the items, including their condition, features, and any relevant details.<br />
            c. You must have the legal right to sell the items you list on the Platform.
            <br /><br />
            Auction Process:
            <br /><br />
            a. By listing an item for auction, you agree to sell the item to the highest bidder at the conclusion of the auction.<br />
            b. You must honor the sale and complete the transaction with the winning bidder.<br />
            c. You may set a reserve price, which is the minimum price at which you are willing to sell the item. If the reserve price is not met, you are not obligated to sell the item.
            <br /><br />
            Fees and Commissions:
            <br /><br />
            a. Bidea Online may charge fees or commissions for using the Platform. These fees will be communicated to you before listing an item.<br />
            b. You are responsible for any applicable taxes or duties associated with the sale of your items.
            <br /><br />
            Communication with Buyers:
            <br /><br />
            a. You are expected to respond promptly and in a professional manner to inquiries and messages from potential buyers.<br />
            b. Open and clear communication is crucial for a successful transaction.
            <br /><br />
            Payment and Delivery:
            <br /><br />
            a. You are responsible for providing accurate payment and delivery instructions to the buyer.<br />
            b. It is your responsibility to deliver the item to the buyer or arrange for its pickup as agreed upon with the buyer.
            <br /><br />
            Dispute Resolution:
            <br /><br />
            a. In the event of any dispute or disagreement between sellers and buyers, Bidea Online encourages both parties to engage in open communication to resolve the issue.<br />
            b. Bidea Online is not responsible for any disputes arising from transactions between sellers and buyers on the Platform.
            <br /><br />
            Limitation of Liability:
            <br /><br />
            a. Bidea Online strives to provide a reliable and secure platform but does not guarantee the accuracy, completeness, or availability of the Platform or any information provided on it.<br />
            b. Bidea Online shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from your use of the Platform.
            <br /><br />
            Governing Law:
            <br /><br />
            a. This Agreement shall be governed by and construed in accordance with the federal laws. Any disputes arising from this Agreement shall be subject to the exclusive jurisdiction of the federal courts.
            <br /><br />
            By using the Bidea Online Platform as a seller, you acknowledge and agree to these terms and conditions. It is your responsibility to review this Agreement periodically for any updates or modifications. If you do not agree with any part of this Agreement, you should refrain from using the Platform.
            If you have any questions or concerns about these terms and conditions, please contact our customer support team at: <i>support@bideaonline.com</i>
            <br /><br />
            Effective Date: July 6, 2023
        </div>
    }
}