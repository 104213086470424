import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Footer from './Footer';
import DevModeModal from './DevModeModal';

export class Layout extends Component {

    render() {
        return (
            <div>
                <DevModeModal />
                <NavMenu onLangChange={lang => this.props.onLangChange(lang)} />
                <div className="page-wrapper">
                    <div className="page-content">
                        <Container>
                            {this.props.children}
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
